<template>
  <v-card class="player" elevation="1">
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-card-text>
      <TaskSelection
        :loading="loading"
        v-model="current_track.task"
        @change="start()"
        defaultLabel="Tätigkeit für Erfassung auswählen"
      ></TaskSelection>
      <div
        v-if="current_track.task && current_track.task.hourly_rate"
        style="white-space: nowrap;"
      >
        {{ counter_earnings }} €
      </div>

      <div class="time">{{ counter }}</div>
      <v-btn
        @click="isRunning ? stop() : start()"
        :disabled="loading || !current_track.task || !current_track.task.id"
        :color="isRunning ? 'success' : null"
      >
        <v-icon>{{ isRunning ? "stop" : "play_arrow" }}</v-icon>
        {{ isRunning ? "Ende" : "Start" }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.player {
  margin: 2rem;
  margin-bottom: 2rem;
  margin-top: 0rem;

  @media (max-width: 690px) {
    margin: 0.75rem;
  }

  .time {
    margin: 7px;
    margin-left: 1rem;
    font-size: 1rem;
  }

  ::v-deep .v-select {
    fieldset {
      border: none;
    }
  }

  ::v-deep .v-btn {
    box-shadow: none;
    background-color: transparent;
  }

  ::v-deep .v-input__slot {
    margin-bottom: 0 !important;
  }

  ::v-deep .v-card__text {
    display: flex;
    align-items: center;
    padding: 0.2rem;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
  ::v-deep
    .task_selection.v-btn:not(.v-btn--round):not(.success).v-btn--has-bg {
    padding: 8px;
    font-size: 1rem;
  }
}
</style>

<script>
import {
  parse,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  addHours,
  addMinutes
} from "date-fns";
let ipcRenderer = null;

export default {
  components: {
    TaskSelection: () =>
      import(
        /* webpackChunkName: "TaskSelection" */ "@/components/TaskSelection.vue"
      )
  },
  mounted() {
    this.interval = setInterval(this.refreshNow, 1000);
    this.loading = true;
    this.$store.dispatch("tracks/getCurrent").then(() => {
      this.loading = false;
    });
    if (this.isInsideElectron) {
      ipcRenderer = require("electron").ipcRenderer;
      ipcRenderer.on("tray-menu-item-click", (e, event) => {
        if (event === "stop" && this.isRunning) {
          this.stop();
        }
      });
    }
  },
  data() {
    return {
      interval: null,
      now: new Date(),
      current_track: {
        task: {}
      },
      loading: true
    };
  },
  computed: {
    isRunning() {
      return this.current_track.start && !this.current_track.end;
    },
    current_track_db() {
      return this.$store.getters["tracks/current"];
    },
    counter() {
      if (this.current_track.start && !this.current_track.end) {
        let x = parse(new Date(this.current_track.start));
        let y = parse(this.now);
        let result = "";
        var temp;
        temp = ("0" + differenceInHours(y, x)).substr(-2);
        result = result + temp + ":";
        x = addHours(x, temp);
        temp = ("0" + differenceInMinutes(y, x)).substr(-2);
        result = result + temp + ":";
        x = addMinutes(x, temp);
        temp = ("0" + differenceInSeconds(y, x)).substr(-2);
        result = result + temp;
        document.title = result;
        return result;
      } else {
        document.title = "Zeiterfassung";
        return "00:00:00";
      }
    },
    counter_earnings() {
      if (this.current_track.start && !this.current_track.end) {
        return this.formatEarnings(
          (differenceInSeconds(this.now, new Date(this.current_track.start)) /
            60 /
            60) *
            this.current_track.task.hourly_rate
        );
      } else {
        return "";
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    counter() {
      if (this.isInsideElectron) {
        let title = "";
        if (
          this.current_track &&
          this.current_track.task &&
          this.current_track.task.name
        ) {
          title += this.current_track.task.name + " ";
        }
        title += this.counter;
        ipcRenderer.send("counter-update", title);
      }
    },
    current_track_db: {
      deep: true,
      immediate: true,
      handler(track) {
        if (track && track.start) {
          this.current_track = {
            ...track,
            task: { ...track.task, customer: track.customer }
          };
        }
      }
    }
  },
  methods: {
    refreshNow() {
      this.now = new Date();
    },
    start() {
      this.$store.dispatch("tracks/add", {
        type: "tracks",
        task_id: this.current_track.task.id,
        start: this.$date(new Date(), "yyyy-MM-dd HH:mm:ss")
      });
    },
    stop() {
      this.loading = true;
      this.$store
        .dispatch("tracks/update", {
          ...this.current_track,
          end: this.$date(new Date(), "yyyy-MM-dd HH:mm:ss")
        })
        .then(() => {
          this.$store.commit("tracks/SET_CURRENT_RESOURCE", {});
          this.current_track = {
            task: {}
          };
          this.loading = false;
        });
    }
  }
};
</script>
